import React from 'react'
import { MqDesktop, MqTablet } from 'streamr-ui/css/consts'
import styled from 'styled-components'
import Container from '~shared/Container'
import Layout from '~shared/Layout'
import PersonaPageHero from '~shared/PersonaPage/PersonaPageHero'
import PersonaPageSection from '~shared/PersonaPage/PersonaPageSection'
import ScrollEncourager from '~shared/ScrollEncourager'
import Spacer from '~shared/Spacer'
import Tatum from '~shared/Tatum'
import Milestone from './Milestone'
import Track from './Track'
import PersonaPageBadge, { ImageWrap } from '../shared/PersonaPage/PersonaPageBadge'
import next from './assets/next.png'
import next2x from './assets/next@2x.png'

const Body = styled.div``

const TimelineContainer = styled(Container)`
    @media ${MqTablet} {
        max-width: 27rem;
    }

    @media ${MqDesktop} {
        max-width: 69rem;
    }
`

const Timeline = styled.div`
    position: relative;
    z-index: 0;

    @media ${MqDesktop} {
        max-width: none;

        ${Track}::before {
            display: none;
        }
    }
`

const UnstyledRoadmap = (props) => (
    <Layout {...props} $backgroundColor="#fafafa">
        <PersonaPageSection background="transparent">
            <PersonaPageHero persona="roadmap" title="Project roadmap" desc={null} />
            <div id="streamr-1.0" />
        </PersonaPageSection>
        <Spacer head="0,,5rem" tail="5rem,7rem,9rem" pad>
            <Tatum />
        </Spacer>
        <BadgeWrap tail="12rem,,16rem">
            <PersonaPageBadge
                iconImage={<img src={next} srcSet={`${next2x} 2x`} alt="" />}
                href="https://blog.streamr.network/whats-next-for-streamr/"
            >
                <NextWrap>
                    <h2>What comes next</h2>
                    <p>Checkout the blog post to learn more about the current product roadmap</p>
                </NextWrap>
            </PersonaPageBadge>
        </BadgeWrap>
        <Body $appearIntersection>
            <TimelineContainer>
                <Timeline>
                    <Track />
                    <div id={ScrollEncourager.TargetID} />
                    <Spacer tail="156px,156px,236px" pad>
                        <Milestone index={0} title="Q1/2024" tag="Streamr 1.0">
                            <ul>
                                <li>DATA tokenomics fully deployed</li>
                                <li>Sponsors create Sponsorships to incentivise Operators</li>
                                <li>Delegators stake on Operators to share revenue</li>
                                <li>Network goes trackerless</li>
                            </ul>
                        </Milestone>
                        <Milestone index={1} title="H1/2023">
                            <ul>
                                <li>Streamr Hub</li>
                                <li>Gasless (metatransaction) support</li>
                                <li>Decentralized encryption key storage</li>
                            </ul>
                        </Milestone>
                        <Milestone index={2} title="H2/2022">
                            <ul>
                                <li>Non-custodial mining</li>
                                <li>Multichain Marketplace</li>
                                <li>Data Unions 3.0</li>
                                <li>Faster and more scalable key exchange</li>
                            </ul>
                        </Milestone>
                        <Milestone index={3} title="H1/2022" tag="Brubeck">
                            <ul>
                                <li>Network is decentralized!</li>
                                <li>Anyone can run nodes</li>
                                <li>Streamr SDK and Streamr nodes</li>
                                <li>Node Operators can earn DATA by running Streamr nodes</li>
                                <li>End-to-end encryption</li>
                                <li>Automatic encryption key exchange</li>
                            </ul>
                        </Milestone>
                        <Milestone index={4} title="2020" tag="Corea">
                            <ul>
                                <li>P2P architecture finalized, all nodes still run by Streamr</li>
                                <li>Performance testing, metrics collection, and comparisons</li>
                                <li>Random network topology per stream controlled by trackers</li>
                                <li>
                                    Stream partitioning &amp; stream-level data retention period
                                </li>
                                <li>Data Unions framework launched</li>
                            </ul>
                        </Milestone>
                        <Milestone index={5} title="2019" tag="Monk">
                            <ul>
                                <li>Ethereum key-based identity and authentication</li>
                                <li>Tamper-proof data with data signing &amp; message chaining</li>
                                <li>Protocol improvements preparing for P2P Network release</li>
                            </ul>
                        </Milestone>
                        <Milestone index={6} title="2018">
                            <ul>
                                <li>Marketplace launch</li>
                                <li>Stability and scalability improvements</li>
                                <li>R&amp;D work started for P2P milestone</li>
                            </ul>
                        </Milestone>
                        <Milestone index={7} title="2017" tag="Launch">
                            <ul>
                                <li>Scalable centralized pub/sub infrastructure</li>
                                <li>JavaScript client library</li>
                                <li>Core UI for managing Streamr resources</li>
                            </ul>
                        </Milestone>
                    </Spacer>
                </Timeline>
            </TimelineContainer>
            <Spacer tail="100px,132px,365px" pad />
        </Body>
    </Layout>
)

const Roadmap = styled(UnstyledRoadmap)`
    color: #0c009a;
    border-bottom: 1px solid #cdcdcd;

    ${ScrollEncourager} path {
        fill: #0324ff;
    }
`

const BadgeWrap = styled(Spacer)`
    margin: 0 auto;
    max-width: 992px;
    padding: 0 32px;

    ${ImageWrap} {
        width: 3.75rem;
        height: 3.75rem;
    }
`

const NextWrap = styled.div`
    padding: 16px 0;

    h2 {
        color: #0c009a;
        font-size: 20px;
        font-weight: 600;
    }

    p {
        color: #525252;
        font-size: 14px;
        font-weight: 400;
    }

    h2,
    p {
        margin: 0;
    }

    h2 + p {
        margin-top: 8px;
    }
`

export default Roadmap
