import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { MqDesktop } from 'streamr-ui/css/consts'

const TrackLine = styled.div`
    background-color: #0324ff;
    bottom: 0;
    left: 16px;
    position: absolute;
    top: 96px;
    width: 1px;
    z-index: -1;

    @media ${MqDesktop} {
        left: 50%;
    }
`

const EndBullet = styled.div`
    position: absolute;
    box-sizing: border-box;
    border: 4px solid #0324ff;
    background-color: #ffffff;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    bottom: 0;
    left: 16px;
    transform: translateX(-8px);

    @media ${MqDesktop} {
        left: 50%;
    }
`

const UnstyledTrack = forwardRef(function UnstyledTrack(props, ref) {
    return (
        <div {...props} ref={ref}>
            <TrackLine $appearDirection="none" />
            <EndBullet />
        </div>
    )
})

const Track = styled(UnstyledTrack)``

export default Track
